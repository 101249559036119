<template>
  <div>
    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :dateSearch="true" />

    <no-records-found
      v-if="showNotFound"
      text="No requests from teams found in our records."
    />
     

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1 mx-1">
        <b-col offset-md="1" offset-xl="4" md="11" xl="8" class="mt-2">
          <div class="d-flex justify-content-end align-items-baseline">
            
            <b-form-datepicker
              v-model="dateFrom"
              :max="maxDate"
              placeholder="Date from"
              reset-button
              class="rounded"
              locale="en"
            />

            <b-form-datepicker
              v-model="dateTo"
              :max="maxDate"
              placeholder="Date to"
              reset-button
              class="ml-2 rounded"
              locale="en"
            />

            <b-form-input
              v-model.trim="search"
              placeholder="Search for Requests"
              class="ml-2"
            />

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon rounded-circle ml-1"
              @click="requestsRefresh"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>

          <div class="text-right">
            <small v-if="dateError" class="text-danger">Both of date fields are required for date range</small>
          </div>
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found"
        empty-filtered-text="No records found"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="requests"
        :fields="fields"
        ref="requestsTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(name)="row">
          <span> {{ row.item.first_name }} {{ row.item.last_name }}</span>
        </template>

        <template #cell(actions)="row">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item
              v-b-modal.accept-request-status
              @click="changeStatus(row.item.ID, row.item.first_name, row.item.last_name)"
            >
              <feather-icon icon="CheckCircleIcon" class="mr-50" />
              <span>Accept Request</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal.reject-request-status
              @click="changeStatus(row.item.ID, row.item.first_name, row.item.last_name)"
            >
              <feather-icon icon="XCircleIcon" class="mr-50" />
              <span>Reject Request</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
    </b-card>

    <accept-request :id="selectedId" :user="selectedUser" @requests-refresh="requestsRefresh"/>
    <reject-request :id="selectedId" :user="selectedUser" @requests-refresh="requestsRefresh"/>
  </div>
</template>
<script>
import {
  BDropdown,
  BDropdownItem,
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BFormDatepicker,
  BCard,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBToggle,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import AcceptRequest from "./components/AcceptRequests.vue";
import RejectRequest from "./components/RejectRequests.vue";

import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    'b-tooltip': VBTooltip,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,

    AcceptRequest,
    RejectRequest,
    NoRecordsFound,
    SkeletonTable,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "name",
          label: "TEAM",
        },
        {
          key: "title",
          label: "ROLE",
        },
        {
          key: "format_created_at",
          label: "REGISTERED AT",
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      search: "",
      maxDate: new Date(today),
      dateFrom: null,
      dateTo: null,
      dateError: false,
      selectedId: false,
      selectedUser: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    requests(ctx, callback) {
      let params = "?page=" + ctx.currentPage;
      if (this.dateFrom && this.dateTo) {
        params += "&date_from=" + this.dateFrom + "&date_to=" + this.dateTo;
      }
     if (this.search) {
        params += "&search=" + this.search;
      }
      this.$http
        .get("/team/requests" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    requestsRefresh() {
      this.selectedId = false;
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
      } else {
        this.dateError = false;
        this.$refs.requestsTable.refresh();
      }
    },
    changeStatus(id, firstName, lastName){
      this.selectedId   = id;
      this.selectedUser = firstName + " " + lastName;
    },
  },
};
</script>