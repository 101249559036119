<template>
  <b-modal
    id="reject-request-status"
    centered
    title="Reject Request"
    ok-title="Reject"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="rejectRequest"
  >
    <b-card-text> Are you sure you want to reject the request from <strong>{{user}}</strong> to be added to its team? </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BCardText,
  },
  props: ["id", "user"],
  emits: ["requests-refresh"],
  methods:{
    rejectRequest(){
      this.$http
        .post("/team/requests/status",{
          team_id: this.id,
          status: "rejected",
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('requests-refresh')
        })
    }
  }
};
</script>
